import React from "react";
import css from "./HasAttended.module.scss";
import { Chip } from "@mantine/core";
import cx from "classnames";
import useMeeting from "hooks/useMeeting";
const HasAttended = ({
  hasAttended,
  setHasAttended,
  selectedEventId,
  source,
  onUpdateHasAttended = () => {},
}) => {

  return (
    <div
      className={cx(css.attend, source === "workspace-page" && css.workspace)}
    >
      <span>Have you attended?</span>
      <div className={css.chips}>
        <Chip
          size="xs"
          variant={(hasAttended || hasAttended === undefined) && "filled"}
          checked={hasAttended || hasAttended === undefined}
          onClick={() => onUpdateHasAttended(true)}
        >
          Yes
        </Chip>
        <Chip
          size="xs"
          variant={hasAttended === false && "filled"}
          checked={hasAttended === false}
          onClick={() => onUpdateHasAttended(false)}
        >
          No
        </Chip>
      </div>
    </div>
  );
};

export default HasAttended;
