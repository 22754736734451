import { showNotification, updateNotification } from "@mantine/notifications";
import { deleteEvent, addOrUpdateStrategicGoal } from "api";
import {
  doc,
  onSnapshot,
  setDoc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import useCalendarStore from "stores/useCalendarStore";
import { useUser } from "stores/useUser";
import useWorkspaceStore from "stores/useWorkspaceStore";
import { notify } from "utils/common";
import { parseFetchedEvents } from "utils/events";
import { FirestoreDB } from "utils/firebase";

const useMeeting = ({ selectedEventId, subscribe_event, recurringEventId }) => {
  const { user } = useUser();
  const {
    updateMetaData: updateWorkspaceMetaData,
    events: workspaceEvents,
    setEvents: setWorkspaceEvents,
    setSelectedEventId: setWorkspaceSelectedEventId,
  } = useWorkspaceStore();

  const { updateMetaData: updateCalendarMetaData } = useCalendarStore();
  const [event, setEvent] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [rating, setRating] = useState(null);
  const [tag, setTag] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [notes, setNotes] = useState(null);
  const [strategicGoal, setStrategicGoal] = useState(null);
  const [hasAttended, setHasAttended] = useState(true);

  const resetMetaData = () => {
    setRating(null);
    setNotes(null);
    setTag("");
  };

  useEffect(() => {
    if (subscribe_event) {
      if (selectedEventId) {
        console.log("FETCHING SINGLE sEVENT");
        try {
          setLoading(true);
          const unsubscribeCalendarEvent = onSnapshot(
            doc(
              FirestoreDB,
              `users/${user.uid}/calendarEvents/${selectedEventId}`
            ),
            async (docSnapshot) => {
              const res = [];
              res.push(docSnapshot.data());
              setEvent(parseFetchedEvents(user, res));
            }
          );

          const unsubscribeMetaData = onSnapshot(
            doc(FirestoreDB, `meetings/${selectedEventId}/users/${user.email}`),
            async (docSnapshot) => {
              const {
                rating: resRating,
                feedback: resFeedback,
                notes: resNotes,
                tag: resTag,
                hasAttended: resHasAttended,
              } = docSnapshot.data() || "";
              setHasAttended(resHasAttended);
              setRating(resRating);
              setFeedback(resFeedback);
              setNotes(resNotes);
              setTag(resTag);
            }
          );

          const unsubscribeStrategicGoal = onSnapshot(
            doc(
              FirestoreDB,
              `strategicGoals/${recurringEventId || selectedEventId}`
            ),
            (docSnapshot) => {
              const goal = docSnapshot.get("goal") || "";
              if (goal) {
                setStrategicGoal(goal);
              }
            }
          );

          // * unsubscribe the listener when component unmounts
          return () => {
            console.log("UNSUBSCRIBING SINGLE EVENT");
            unsubscribeCalendarEvent();
            unsubscribeMetaData();
            unsubscribeStrategicGoal();
            resetMetaData();
          };
        } catch (error) {
          notify("Error while fetching event detail", "error");
          setError(true);
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  }, [selectedEventId, subscribe_event, user]);

  const {
    mutate: deleteEventMutation,
    isPending: isDeletingEvent,
    isError: isDeletingEventError,
  } = useMutation({
    mutationFn: async (eventId) => {
      const res = await deleteEvent(eventId, user.uid);
      if (!res.status) {
        throw new Error(res?.message);
      }
    },
    onMutate: (eventId) => {
      // Snapshot the previous events and selected event
      const previousEvents = [...workspaceEvents];
      const previousSelectedId = selectedEventId;

      // Find the index of the event to delete
      const currentEventIndex = previousEvents.findIndex(
        (event) => event.eventId === eventId
      );

      // Determine the next event to select
      let nextEventId = null;
      if (currentEventIndex < previousEvents.length - 1) {
        nextEventId = previousEvents[currentEventIndex + 1].eventId;
      } else if (currentEventIndex > 0) {
        nextEventId = previousEvents[currentEventIndex - 1].eventId;
      }

      // Optimistically update the selected event
      setWorkspaceSelectedEventId(nextEventId);

      setWorkspaceEvents(
        previousEvents.filter((event) => event.eventId !== eventId)
      );
      // Return a context object with the snapshotted values
      return {
        previousEvents,
        previousSelectedId,
        deletedEventId: eventId,
      };
    },
    onError: (err, eventId, context) => {
      // Restore the previous selected event
      setWorkspaceSelectedEventId(context.previousSelectedId);
      setWorkspaceEvents(context.previousEvents);
      notify("Error while deleting event from calendar", "error");
    },
    onSuccess: () => {
      notify("Event deleted from calendar successfully!", "success");
    },
  });

  async function submitRating(rating, eventId) {
    updateWorkspaceMetaData("rating", eventId, rating);
    updateCalendarMetaData("rating", eventId, rating);
    try {
      const eventRef = doc(FirestoreDB, `meetings/${eventId}`);
      const eventDoc = await getDoc(eventRef);
      if (eventDoc.exists()) {
        await setDoc(
          doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
          { rating },
          { merge: true }
        );
      } else {
        await setDoc(doc(FirestoreDB, `meetings/${eventId}`), { id: eventId });
        await setDoc(
          doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
          { rating },
          { merge: true }
        );
      }
      notify("Rating submitted!", "success");
    } catch (error) {
      console.log(error);
      notify("Error while submitting rating", "error");
    }
  }
  async function submitHasAttended(hasAttended, eventId) {
    updateWorkspaceMetaData("hasAttended", eventId, hasAttended);
    updateCalendarMetaData("hasAttended", eventId, hasAttended);
    // setting in meetings collection
    try {
      const eventRef = doc(FirestoreDB, `meetings/${eventId}`);
      const eventDoc = await getDoc(eventRef);
      if (eventDoc.exists()) {
        await setDoc(
          doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
          { hasAttended },
          { merge: true }
        );
      } else {
        await setDoc(doc(FirestoreDB, `meetings/${eventId}`), { id: eventId });
        await setDoc(
          doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
          { hasAttended },
          { merge: true }
        );
      }
      //setting in users collection
      await setDoc(
        doc(FirestoreDB, `users/${user?.uid}/calendarEvents/${eventId}`),
        { hasAttended },
        { merge: true }
      );
      notify("Status submitted!", "success");
    } catch (error) {
      console.log(error);
      notify("Error while submitting status", "error");
    }
  }

  async function submitTag(selectedTag, eventId) {
    updateWorkspaceMetaData("tag", eventId, selectedTag);
    updateCalendarMetaData("tag", eventId, selectedTag);
    console.log(eventId, "id");
    try {
      const eventRef = doc(FirestoreDB, `meetings/${eventId}`);
      const eventDoc = await getDoc(eventRef);
      if (eventDoc.exists()) {
        await setDoc(
          doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
          { tag: selectedTag },
          { merge: true }
        );
      } else {
        await setDoc(doc(FirestoreDB, `meetings/${eventId}`), { id: eventId });
        await setDoc(
          doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
          { tag: selectedTag },
          { merge: true }
        );
      }
      notify("Tag updated", "success");
    } catch (error) {
      console.log(error);
      notify("Error while submitting tag", "error");
    }
  }

  async function submitFeedback(feedbackTags, customFeedback, eventId) {
    let feedback = {
      feedbackTags,
      customFeedback,
    };
    updateWorkspaceMetaData("feedback", eventId, feedback);
    updateCalendarMetaData("feedback", eventId, feedback);
    try {
      await setDoc(
        doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
        { feedback },
        { merge: true }
      );
      notify("Feedback submitted!", "success");
    } catch (error) {
      console.log(error);
      notify("Error while submitting feedback", "error");
    }
  }

  async function saveNotes(notes, eventId) {
    if (!notes) return;
    try {
      await setDoc(
        doc(FirestoreDB, `meetings/${eventId}/users/${user.email}`),
        { notes },
        { merge: true }
      );
      // notify("Notes Saved", "success");
    } catch (error) {
      console.log(error);
      notify("Error while saving notes", "error");
    }
  }

  async function copyNotesToNextRecursion(
    notes,
    eventId,
    recurringEventId,
    startDateTime
  ) {
    showNotification({
      id: `${eventId}-copying-notes`,
      loading: true,
      title: "Copying...",
      message: "Copying notes to next iteration of same meeting",
      autoClose: false,
      disallowClose: true,
    });
    try {
      // query for getting next iteration
      const q = query(
        collection(FirestoreDB, `users/${user?.uid}/calendarEvents`),
        where("recurringEventId", "==", recurringEventId),
        where(
          "start.dateTime",
          ">",
          moment(startDateTime).format("YYYY-MM-DD") + "T23:59:59.999Z"
        ),
        orderBy("start.dateTime"),
        limit(1)
      );

      const querySnapshot = await getDocs(q);

      // if there is no next event, then showing message
      if (querySnapshot.empty) {
        updateNotification({
          id: `${eventId}-copying-notes`,
          color: "red",
          title: "Can't copy next!",
          message:
            "This is the last iteration of this slot, can't copy to any other meeting",
          autoClose: 5000,
        });
        return;
      } else {
        // if there is next event
        querySnapshot.forEach(async (document) => {
          // checking if next event already contains some notes?
          const eventDoc = await getDoc(
            doc(FirestoreDB, `meetings/${document.id}/users/${user.email}`)
          );

          // if contains then concating prev notes of event + new coming notes
          if (eventDoc.exists()) {
            const eventData = eventDoc.data();
            if (eventData.notes) {
              const prevNotes = eventData.notes;
              const newNotes = prevNotes + notes;
              await saveNotes(newNotes, document.id);
            }
          } else {
            // else only saving new coming notes
            // doc.data() is never undefined for query doc snapshots
            await saveNotes(notes, document.id);
          }
        });
      }

      updateNotification({
        id: `${eventId}-copying-notes`,
        color: "teal",
        title: "Copied!",
        message:
          "Your same notes are now available in the next iteration of same meeting",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      notify("Error while copying notes", "error");
    }
  }

  async function submitGoal(goal, eventId) {
    try {
      // The API will handle the determination of whether the meeting is a recurring event
      // and whether the user is the organizer of the current event.
      // Pass the goal text and eventId as parameters.
      await addOrUpdateStrategicGoal(goal, eventId);
      setStrategicGoal(goal);
    } catch (err) {
      console.log(err);
      notify("Error while submitting goal", "error");
    } finally {
      console.log("GOAL UPDATED:" + goal);
    }
  }
  return {
    hasAttended,
    setHasAttended,
    event,
    isLoading,
    isError,
    submitRating,
    rating,
    submitTag,
    tag,
    submitHasAttended,
    submitFeedback,
    notes,
    saveNotes,
    feedback,
    submitGoal,
    strategicGoal,
    copyNotesToNextRecursion,
    deleteEventMutation,
    isDeletingEvent,
    isDeletingEventError,
  };
};

export default useMeeting;
