import { Badge, Flex, Loader, Menu, Text } from "@mantine/core";
import Tooltip from "components/Tooltip/Tooltip";
import { truncate } from "lodash";
import React, { useState } from "react";
import { TbTargetArrow } from "react-icons/tb";
import { CgInfo } from "react-icons/cg";
import { HiPencil } from "react-icons/hi";
const StrategicGoal = ({
  selectedGoal,
  setSelectedGoal,
  goals,
  selectedEvent,
  submitGoal,
  refreshMetaData = () => {},
}) => {
  const prevGoal = selectedGoal;
  const [updating, setUpdating] = useState(false);
  const handleGoalUpdate = async (goal) => {
    if (goal !== prevGoal) {
      setSelectedGoal(goal);
      try {
        setUpdating(true);
        await submitGoal(goal, selectedEvent?.eventId);
        refreshMetaData();
      } catch (err) {
        throw err;
      } finally {
        setUpdating(false);
      }
    }
  };

  return (
    <Flex align={"center"} gap={".3rem"}>
      {updating ? (
        <Badge sx={{ display: "flex" }}>
          <Text inline>Updating...</Text>
        </Badge>
      ) : (
        <Menu
          shadow="md"
          disabled={!selectedEvent?.organizer?.self || updating}
          key={selectedEvent?.eventId}
        >
          <Menu.Target>
            <Badge
              size="lg"
              sx={{
                width: "fit-content",
                cursor: "pointer",
                maxWidth: "16rem",
              }}
            >
              <Tooltip
                label={`${selectedGoal ? selectedGoal : "Strategic goal"}`}
                style={{ textTransform: "lowercase" }}
              >
                <Flex align={"center"} justify={"center"} gap={".5rem"}>
                  <TbTargetArrow size={15} />
                  <Text sx={{ textTransform: "lowercase" }} fw={"normal"}>
                    {truncate(selectedGoal, { length: 24 }) ||
                      "Strategic goal ?"}
                  </Text>
                  {selectedEvent?.organizer?.self && <HiPencil />}
                </Flex>
              </Tooltip>
            </Badge>
          </Menu.Target>

          <Menu.Dropdown>
            {goals.map((goal, i) => (
              <Menu.Item key={i} onClick={() => handleGoalUpdate(goal)}>
                {goal}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}

      {updating ? (
        <Loader size={16} />
      ) : (
        <Tooltip
          label={"Strategic goal can only be updated by meeting's organizer"}
        >
          <div style={{ cursor: "pointer", marginTop: 5 }}>
            <CgInfo size={20} color="grey" />
          </div>
        </Tooltip>
      )}
    </Flex>
  );
};

export default StrategicGoal;
