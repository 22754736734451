import {
  httpsCallable,
  getFunctions,
  connectFunctionsEmulator,
} from "firebase/functions";
import { CloudFunctions } from "utils/firebase";
import { COMPANY_NAME } from "./constants";

if (process.env["REACT_APP_FUNCTIONS_EMULATOR"]) {
console.log("CONNECTING TO EMULATOR");
connectFunctionsEmulator(getFunctions(), "127.0.0.1", 5001);
}

export const getTeamListForMeetingNotes = async (loginEmail) => {
  try {
    const getMyTeam = httpsCallable(CloudFunctions, "getMyTeam");

    const response = await getMyTeam({
      email: loginEmail,
    });
    if (response.data.status) {
      return response.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const checkUserOrganization = async (loginEmail) => {
  try {
    const checkIfUserHasExistingOrganization = httpsCallable(
      CloudFunctions,
      "checkIfUserHasExistingOrganization"
    );

    console.log(loginEmail);
    const response = await checkIfUserHasExistingOrganization({
      email: loginEmail,
    });
    if (response.data.status) {
      return response.data;
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const ValidateUserInputs = async (
  loginEmail,
  fname,
  lname,
  department
) => {
  try {
    const ValidateUserAndConnectToFinch = httpsCallable(
      CloudFunctions,
      "ValidateUserAndConnectToFinch"
    );

    const response = await ValidateUserAndConnectToFinch({
      email: loginEmail,
      firstName: fname,
      lastName: lname,
      department: department,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const MeetingDetails = async (
  email = "",
  meetingIds = [],
  orgId = ""
) => {
  try {
    const getMeetingDetails = httpsCallable(
      CloudFunctions,
      "getMeetingDetails"
    );

    const response = await getMeetingDetails({
      meetingIds,
      email,
      orgId,
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const SendRunningLate = async (data) => {
  try {
    const SendRunningLateEmail = httpsCallable(
      CloudFunctions,
      "sendRunningLateEmail"
    );

    const response = await SendRunningLateEmail(data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const AddMeetingNotes = async (loginEmail, meetingId, meetingNotes) => {
  try {
    const addMeetingNotesData = httpsCallable(
      CloudFunctions,
      "addMeetingNotesData"
    );
    const response = await addMeetingNotesData({
      email: loginEmail,
      meeting_Id: meetingId,
      meeting_Notes: meetingNotes,
    });
    return response;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const connectToFinch = async (
  loginEmail,
  orgnizationID,
  finchId,
  userUid
) => {
  try {
    const connectToFinch = httpsCallable(CloudFunctions, "connectToFinch");
    const response = await connectToFinch({
      email: loginEmail,
      orgId: orgnizationID,
      finchId: finchId,
      userUid: userUid,
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getTags = async (userUid, plan, orgnizationID, department) => {
  try {
    const getTags = httpsCallable(CloudFunctions, "getTags");
    const response = await getTags({
      userUid: userUid,
      userPlan: plan,
      orgId: orgnizationID,
      department: department,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const addActionItem = async (
  fromUserEmail = null,
  toUserEmail,
  html,
  priority = 2,
  effort = 2,
  due_date = null
) => {
  try {
    const addActionItemFunction = httpsCallable(
      CloudFunctions,
      "addActionItem"
    );
    const response = await addActionItemFunction({
      email: toUserEmail,
      html: html,
      priority: priority,
      effort: effort,
      due_date: due_date,
      assigned_user: fromUserEmail,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const sendInvitationsToEmails = async (
  user_Uid,
  from_Email,
  to_emails
) => {
  try {
    const createAndSendInviteLinkEmails = httpsCallable(
      CloudFunctions,
      "createAndSendInviteLinkEmails"
    );
    const response = await createAndSendInviteLinkEmails({
      uid: user_Uid,
      fromEmail: from_Email,
      toEmails: to_emails,
    });
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const SendEmailNotes = async (data) => {
  try {
    const SendEmailNotes = httpsCallable(
      CloudFunctions,
      "sendMeetingNotesEmail"
    );

    const response = await SendEmailNotes(data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const sendActionItems = async (data) => {
  try {
    const sendActionItemsEmail = httpsCallable(
      CloudFunctions,
      "sendActionItemsEmail"
    );

    const response = await sendActionItemsEmail(data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const respondToInvitation = async (
  invitationFromUID,
  invitationToUID,
  invitationToEmailID,
  status,
  loginEmail
) => {
  try {
    const respondToInvitation = httpsCallable(
      CloudFunctions,
      "respondToInvitation"
    );

    const response = await respondToInvitation({
      fromUserID: invitationFromUID,
      toUserID: invitationToUID || "",
      toEmailID: invitationToEmailID,
      status: status,
      loginEmail: loginEmail,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const decryptInviteTokenAndReturnUsers = async (token) => {
  try {
    const decryptInviteTokenAndReturnUsers = httpsCallable(
      CloudFunctions,
      "decryptInviteTokenAndReturnUsers"
    );

    const response = await decryptInviteTokenAndReturnUsers({
      token: token,
    });

    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchPeopleRated = async (data) => {
  try {
    const fetchPeopleRated = httpsCallable(CloudFunctions, "fetchPeopleRated2");

    const response = await fetchPeopleRated(data);
    return response.data.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
export const fetchPersonRated = async (data) => {
  try {
    const fetchPersonRated = httpsCallable(CloudFunctions, "fetchPersonRated2");

    const response = await fetchPersonRated(data);
    return response.data.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const slackChannelsList = async () => {
  try {
    const slackChannelsList = httpsCallable(
      CloudFunctions,
      "listSlackChannels"
    );
    const response = await slackChannelsList();
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const slackUsersList = async () => {
  try {
    const slackUsersList = httpsCallable(CloudFunctions, "listSlackUsers");
    const response = await slackUsersList();
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const SendSlackNotes = async (data) => {
  try {
    const postMessageToSlack = httpsCallable(
      CloudFunctions,
      "postMessageToSlack"
    );

    const response = await postMessageToSlack(data);
    return response.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getCostOfUserMeetings = async (email, type, duration) => {
  try {
    const costOfUserMeetings = httpsCallable(
      CloudFunctions,
      "costOfUserMeetings2"
    );

    const response = await costOfUserMeetings({
      userEmail: email,
      asAn: type,
      pastDays: duration,
    });
    return response.data.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getNoAndHoursOfMeetings = async (
  typeOfCheck,
  asAn,
  userEmail,
  organizationId,
  departmentName
) => {
  try {
    const noAndHrsOfMeetings = httpsCallable(
      CloudFunctions,
      "noAndHrsOfMeetings"
    );

    const res = await noAndHrsOfMeetings({
      typeOfCheck,
      asAn,
      userEmail,
      organizationId,
      departmentName,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getIndividualRating = async (email) => {
  try {
    const rating = httpsCallable(CloudFunctions, "getIndividualRating2");
    const res = await rating({
      userEmail: email,
    });
    return res.data.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getDirectReportees = async (email, duration) => {
  try {
    const directReportees = httpsCallable(
      CloudFunctions,
      "getDirectReportees2"
    );
    const res = await directReportees({
      userEmail: email,
      pastDays: duration,
    });
    return res.data.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const mapUserToFinch = async (email, finchId) => {
  try {
    const tempMapUserToFinch = httpsCallable(
      CloudFunctions,
      "tempMapUserToFinch"
    );
    const res = await tempMapUserToFinch({
      email: email,
      finchId: finchId,
      companyName: COMPANY_NAME,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const fetchOrgMeetingEffectiveness = async (orgId, duration) => {
  try {
    const getOrgMeetingEffectiveness = httpsCallable(
      CloudFunctions,
      "getOrgMeetingEffectiveness"
    );
    const res = await getOrgMeetingEffectiveness({
      organizationId: orgId,
      pastDays: duration,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchMeVsMeeetingForOrg = async (orgId, duration) => {
  try {
    const getMeVSMeetingTime = httpsCallable(
      CloudFunctions,
      "getMeVSMeetingTime"
    );
    const res = await getMeVSMeetingTime({
      organizationId: orgId,
      pastDays: duration,
    });
    return res.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchMeetingDisForUser = async (userEmail, duration, asAn) => {
  try {
    const distributionOfMeetingsForUser = httpsCallable(
      CloudFunctions,
      "distributionOfMeetingsForUser2"
    );
    const res = await distributionOfMeetingsForUser({
      userEmail,
      pastDays: duration,
      asAn,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const OrgMeetingDetails = async (organizationId, pastDays) => {
  try {
    const getOrgMeetingDetails = httpsCallable(
      CloudFunctions,
      "getOrgMeetingDetails"
    );
    const res = await getOrgMeetingDetails({ organizationId, pastDays });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const MeTimeMeetingDetails = async (
  userEmail,
  organizationId,
  pastDays
) => {
  try {
    const getMeTimeMeetingDetails = httpsCallable(
      CloudFunctions,
      "getMeTimeMeetingDetails"
    );
    const res = await getMeTimeMeetingDetails({
      userEmail,
      organizationId,
      pastDays,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const actionsSummary = async (userId, pastDays) => {
  try {
    const getActionsSummary = httpsCallable(
      CloudFunctions,
      "getActionsSummary2"
    );
    const res = await getActionsSummary({ userId, pastDays });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const deleteUserData = httpsCallable(CloudFunctions, "removeUser");
    const res = await deleteUserData({ uid: "Iu44ZB6218gP8nRHmCvNHiOGuK33" });
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteEvent = async (eventId, userId) => {
  try {
    const deleteCalendarEvent = httpsCallable(
      CloudFunctions,
      "deleteCalendarEvent"
    );
    const res = await deleteCalendarEvent({
      eventId,
      userId,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const addOrUpdateStrategicGoal = async (goal, eventId) => {
  try {
    const strategicGoal = httpsCallable(CloudFunctions, "strategicGoal");
    const res = await strategicGoal({ goal, eventId });
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getStrategicGoal = async (eventId) => {
  try {
    const strategicGoal = httpsCallable(CloudFunctions, "getStrategicGoal");
    const res = await strategicGoal({ eventId });
    return res.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const createCalendarEvent = async (event) => {
  try {
    const create = httpsCallable(CloudFunctions, "createCalendarEvent");
    const res = await create(event);
    if (res.data.status) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const findAvailableTimeApi = async (
  guestEmails,
  startDate,
  endDate,
  slotDurationMinutes
) => {
  try {
    const findAvailableTime = httpsCallable(
      CloudFunctions,
      "findCommonAvailability"
    );
    const res = await findAvailableTime({
      guestEmails,
      startDate,
      endDate,
      slotDurationMinutes,
    });
    if (res.data.status) {
      return res.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};
